<template>
  <Layout class="packageManagement">
    <h3 id="packageManagement">
      应用包发布
    </h3>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="140px"
      label-position="right"
      style="width: 520px;"
    >
      <el-form-item
        label="应用名"
        prop="name"
        :error="asyncMssages.name"
      >
        <el-input
          v-model="formData.name"
          placeholder="支持中英文, 字母和特殊符号"
        />
      </el-form-item>

      <el-form-item
        label="选择系统"
        prop="platform"
        :error="asyncMssages.platform"
      >
        <el-radio
          v-model="formData.platform"
          label="android"
        >
          Android
        </el-radio>
        <el-radio
          v-model="formData.platform"
          label="ios"
        >
          IOS
        </el-radio>
        <el-radio
          v-model="formData.platform"
          label="pc"
        >
          PC
        </el-radio>
        <el-radio
          v-model="formData.platform"
          label="h5"
        >
          H5
        </el-radio>
      </el-form-item>

      <el-form-item
        label="包名"
        prop="packageName"
        :error="asyncMssages.packageName"
      >
        <el-input
          v-model="formData.packageName"
          placeholder="需从应用市场获取"
        />
      </el-form-item>

      <el-form-item
        label="开发者账号"
        prop="developerAccount"
        :error="asyncMssages.developerAccount"
      >
        <el-input v-model="formData.developerAccount" />
      </el-form-item>

      <el-form-item
        label="下载地址"
        prop="appStoreUrl"
        :error="asyncMssages.appStoreUrl"
      >
        <el-input
          v-model="formData.appStoreUrl"
          :title="formData.appStoreUrl"
          placeholder="下载地址"
        />
      </el-form-item>

      <el-form-item
        label="APPKEY"
        prop="appKey"
        :error="asyncMssages.appKey"
      >
        <el-input
          v-model="formData.appKey"
          placeholder="open平台获取"
        />
      </el-form-item>

      <el-form-item
        label="类型"
        prop="appType"
        :error="asyncMssages.appType"
      >
        <el-select
          v-model="formData.appType"
          placeholder="请选择类型"
          clearable
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="渠道"
        prop="channel"
        :error="asyncMssages.channel"
      >
        <el-select
          v-model="formData.channel"
          placeholder="请选择类型"
          :disabled="true"
        >
          <el-option
            v-for="item in channelList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="更新方式"
        prop="appUpdateType"
        :error="asyncMssages.appUpdateType"
      >
        <el-select
          v-model="formData.appUpdateType"
          placeholder="请选择更新方式"
          clearable
        >
          <el-option
            v-for="item in updateLevel"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="备注"
        prop="remark"
        :error="asyncMssages.remark"
      >
        <el-input
          v-model="formData.remark"
          type="textarea"
          :autosize="{ minRows: 4 }"
          placeholder="请输入备注"
          clearable
        />
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          :loading="isReleaseing"
          @click="handleSubmit"
        >
          确定发布
        </el-button>
        <el-button @click="handleReset">
          清除当前内容
        </el-button>
      </el-form-item>
    </el-form>

    <h3>已发布应用</h3>

    <el-table
      :data="listInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column type="expand">
        <template #default="{row}">
          <el-form
            :model="row"
            label-position="right"
            class="application-package-expand"
            label-width="120px"
          >
            <el-form-item label="开发者账号">
              <span>{{ row.developerAccount }}</span>
            </el-form-item>
            <el-form-item label="类型">
              <span>{{ typeMap[row.appType] || "未知" }}</span>
            </el-form-item>
            <el-form-item label="渠道">
              <span>{{ row.channel }}</span>
            </el-form-item>
            <el-form-item label="更新方式">
              <span>{{ updateLevelMap[row.appUpdateType] || "未知" }}</span>
            </el-form-item>
            <el-form-item label="备注">
              <span>{{ row.remark }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="应用名"
        min-width="100px"
      />
      <el-table-column
        prop="packageName"
        label="包名"
        min-width="250px"
      />
      <el-table-column
        prop="appKey"
        label="APPKEY"
        min-width="250px"
      />
      <el-table-column
        prop="appStoreUrl"
        label="下载地址"
        width="80px"
      >
        <template #default="{row}">
          <a
            v-if="row.appStoreUrl"
            :href="row.appStoreUrl"
            :title="row.appStoreUrl"
            target="_blank"
          >下载地址</a>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        width="240"
        label="操作"
      >
        <template v-slot="{ row }">
          <a
            href="#packageManagement"
            class="anchor-link"
          >
            <el-button
              type="primary"
              @click="handleEditRow(row)"
            >
              编辑
            </el-button>
          </a>
          <el-button
            type="warn"
            :loading="row.delLoading"
            @click="handleDelRow(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="searchForm.pageNum"
      :limit.sync="searchForm.pageSize"
      @pagination="queryApplicationList"
    />
  </Layout>
</template>

<script>
import { cloneDeep } from 'lodash'

import { addApp, appList, editApp, delApp } from '@/api/package.js'

const updateLevel = [
  { value: 1, name: '灵活更新' },
  { value: 2, name: '强制更新' },
  { value: 3, name: '其他' }
]
const updateLevelMap = {
  1: '灵活更新',
  2: '强制更新',
  3: '其他'
}

const channelList = [{ value: 'netboom', name: 'netboom' }]
const typeList = [
  { value: 1, name: '主包' },
  { value: 2, name: '马甲包' },
  { value: 3, name: '多语言包' },
  { value: 4, name: 'TF包' },
  { value: 5, name: '其他' }
]
const typeMap = {
  1: '主包',
  2: '马甲包',
  3: '多语言包',
  4: 'TF包',
  5: '其他'
}

let errorIndex = 1
export default {
  name: 'ApplicationPackage',
  data () {
    const packageNameCheck = (rule, value, callback) => {
      if (this.formData.platform === 'android' && !this.formData.packageName) {
        callback(new Error('Android包必需填写包名'))
      } else {
        callback()
      }
    }
    return {
      updateLevel,
      updateLevelMap,
      typeList,
      typeMap,
      channelList,
      formData: {
        name: '',
        platform: 'android',
        packageName: '',
        developerAccount: '',
        appStoreUrl: '',
        appKey: '',
        appType: '',
        channel: 'netboom',
        appUpdateType: '',
        remark: ''
      },
      asyncMssages: {
        name: '',
        platform: '',
        packageName: '',
        developerAccount: '',
        appStoreUrl: '',
        appKey: '',
        appType: '',
        channel: '',
        appUpdateType: '',
        remark: ''
      },
      isReleaseing: false,
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      isEdit: false,
      searchForm: {
        pageNum: 1,
        pageSize: 20
      },
      rules: {
        name: [{ required: true, message: '应用名必填' }],
        platform: [{ required: true, message: '系统必选' }],
        packageName: [{ required: true, validator: packageNameCheck }],
        developerAccount: [{ required: true, message: '应用名必填' }],
        appStoreUrl: [{ required: true, message: '下载地址必填' }],
        appKey: [{ required: true, message: 'appKey必填' }],
        appType: [{ required: true, message: '应用包类型必选' }],
        channel: [{ required: true, message: '渠道必选' }],
        appUpdateType: [{ required: true, message: '更新方式必选' }]
      }
    }
  },
  created () {
    this.queryApplicationList()
  },
  methods: {
    handleSubmit () {
      this.isReleaseing = true
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$confirm('确定发布?', '提示', { type: 'warning ' })
            .then(() => {
              if (!this.isEdit) {
                this.handleAddApp()
              } else {
                this.handleEditApp()
              }
            })
            .catch(() => {
              this.isReleaseing = false
            })
        } else {
          this.isReleaseing = false
        }
      })
    },
    handleAddApp () {
      addApp(this.formData)
        .then(res => {
          if (res.code === 200) {
            if (res.data.success) {
              this.$message.success('发布成功')
              this.queryApplicationList()
              this.handleReset()
            } else {
              this.$message.warning(res.data.msg)
              if (res.data.field) {
                this.asyncMssages[res.data.field] = `Error_${errorIndex++}: ${
                  res.data.msg
                }`
              }
            }
          }
        })
        .finally(() => {
          this.isReleaseing = false
        })
    },
    handleEditApp () {
      const {
        id,
        name,
        platform,
        packageName,
        developerAccount,
        appStoreUrl,
        appKey,
        appType,
        channel,
        appUpdateType,
        remark
      } = this.formData
      editApp({
        id,
        name,
        platform,
        packageName,
        developerAccount,
        appStoreUrl,
        appKey,
        appType,
        channel,
        appUpdateType,
        remark
      })
        .then(res => {
          if (res.code === 200) {
            if (res.data.success) {
              this.$message.success('更新成功')
              this.queryApplicationList()
              this.handleReset()
            } else {
              this.$message.warning(res.data.msg)
              if (res.data.field) {
                this.asyncMssages[res.data.field] = `Error_${errorIndex++}: ${
                  res.data.msg
                }`
              }
            }
          }
        })
        .finally(() => {
          this.isReleaseing = false
        })
    },
    handleReset () {
      this.formData = {
        name: '',
        platform: 'android',
        packageName: '',
        developerAccount: '',
        appStoreUrl: '',
        appKey: '',
        appType: '',
        channel: 'netboom',
        appUpdateType: '',
        remark: ''
      }
      this.isEdit = false
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    queryApplicationList () {
      appList(this.searchForm).then(res => {
        if (res.code === 200) {
          this.listInfo.list = res.data?.list || []
          this.listInfo.total = res.data?.total || 0
        }
      })
    },
    handleEditRow (row) {
      this.formData = cloneDeep(row)
      this.isEdit = true
    },
    handleDelRow (row) {
      this.$confirm('确定删除吗', '提示', { type: 'warning ' }).then(() => {
        this.$set(row, 'delLoading', true)

        delApp({ id: row.id })
          .then(res => {
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.queryApplicationList()
            }
          })
          .finally(() => {
            this.$set(row, 'delLoading', false)
          })
      })
    }
  }
}
</script>

<style lang="less">
.packageManagement {
  .el-table__expanded-cell {
    background-color: #fafcfd;
    .application-package-expand {
      label {
        width: 100px;
        color: #99a9bf;
      }
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
}
.application-package-expand {
  .el-form-item {
    margin-bottom: 0;
  }
}
.anchor-link {
  display: inline;
  margin-right: 20px;
}
</style>
